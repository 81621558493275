<template>
    <div class="main" style="padding-bottom:15px;">
        <div style="display: flex;justify-content: space-between;">
            <a-breadcrumb style="padding: 16px 0; background: #f0f2f5">
                <a-breadcrumb-item>服务订单</a-breadcrumb-item>
            </a-breadcrumb>
            <a-button class="ant-btn-primary" style="margin-top: 10px;" @click="goServiceOrder">已购买服务</a-button>
        </div>
      
      <a-row style="background-color: #fff;">
        <a-col>
          <div>
            <div class="version-list-title">
                <div style="width:80px;" class="label lh-10">服务类型</div>
                <div style="width:calc(100% - 80px);display: flex;justify-self: start;">
                <span class="packet-items" v-for="item,idx in modules" :key="'packet'+idx" :class="{'active':checkModule === item.id}" @click="checkSel(item.id,item.price,item.description)">
                    {{item.name}}
                </span>
                </div>
            </div>
            <div class="version-list-title">
              <div v-html="description">

              </div>
            </div>
            <div class="version-list-title">
                <div style="width:80px;" class="label lh-10">联系方式</div>
                <div style="width:100%;">
                    <div style="width:calc(20% - 80px);">
                        <a-input placeholder="联系方式" v-model="mobile" />
                    </div>
                    <div class="tips-info">
                        <span>下单后，可能需要和您联系确认服务需求，请您填写准确无误的手机号码。</span>
                    </div>
                    
                </div>
            </div>
            <div class="version-list-title">
                <div style="width:80px;" class="label lh-10">有效时长</div>
                <div style="width: 100%;">
                    <div style="width:calc(100% - 80px);display: flex;justify-self: start;">
                        <span class="packet-items time-active">
                            <a-popover>1年</a-popover>
                        </span>
                    </div>
                    <div class="tips-info">
                        <span>服务订单有效期为1年，请在1年内使用，超过有效期自动失效，无法恢复。</span>
                    </div>
                </div>
            </div>
          </div>
        </a-col>
      </a-row>

        <div class="foot">
            <a-row style="background-color: #fff;">
            <a-col style="display: flex;justify-content: flex-end;">
            <div class="foot-item" style="margin-right: 150px;">
                <div class="foot-item-list">
                    <div>
                        <span style="font-weight: 800;line-height: 30px;margin-right: 15px;">购买个数</span>
                    </div>
                    <a-input-number id="inputNumber" v-model="num" :min="1" :max="99" :precision="0" @change="onChange" />
                </div>
                <div>按次提供服务，默认为1次，如需多次请选择个数</div>
            </div>
            <div class="foot-item">
                <div class="foot-item-list">
                    <a-checkbox @change="checkContract">
                      我已阅读并同意
                    </a-checkbox>
                    <a href="javascript:;" @click="showRule = true">服务条款</a>、
                    <a href="javascript:;" @click="showPrivacy = true">隐私政策</a>
                </div>
                <div class="foot-item-list" style="margin: 15px;">
                    <div style="margin-right: 50px;font-size: 24px;">
                        <span style="margin-right: 10px;">合计</span>
                        <span style="font-weight: 800;color: #FF833D;">{{total}}</span>
                    </div>
                    <div style="margin-top: 8px;">
                        <a-button class="ant-btn-primary" @click="toPay">立即下单</a-button>
                    </div>
                </div>
            </div>
            </a-col>
            </a-row>
        </div>
        
  
      <!-- 选择支付方式弹窗 -->    
       <a-modal v-model="selectPayType" title="选择支付方式" :keyboard="false" :maskClosable="false" :footer="null"
        @cancel="noSurePayType">
        <div style="color:red;">购买系统版本或者增值功能完成后请重新登陆生效</div>
        <a-radio-group v-model="payType" @change="changePayType">
          <a-radio v-for="item in payTypes" :key="item.key" :value="item.key">
            {{item.value}}
          </a-radio>
        </a-radio-group>
  
        <div style="text-align: center" v-show="showWxScan">
          <!-- 装微信支付二维码的容器 -->
          <div style="display: flex; justify-content: center;marginTop:10px;" class="qrcode" ref="qrCodeUrl"></div>
          <p style="marginTop:10px;">
            <img src="https://vip.51baocan.com/static/home/pay/img/step.png" alt srcset />
          </p>
          <a-button icon="transaction" style="background-color: #00c800; color: white; margin: 15px auto"
            @click="wxPaySuccess">支付成功</a-button>
        </div>
        <div id="aliPay"></div>
        <div id="unionPay"></div>
        <div id="quickPay"></div>
      </a-modal>
  
      <!-- 对公汇款 -->
      <a-modal v-model="showUnderLine" title="对公汇款订单详情可在订单与发票管理中查看" :footer="null" :width="400">
        <div style="text-align: center">
          <div>
            <a-row>
              <a-col :span="5" style="textAlign:right;">
                开户名称:
              </a-col>
              <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
                福建尚汇信息科技有限公司
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="5" style="textAlign:right;">
                开<i style="paddingLeft:7px;" />户<i style="paddingLeft:7px;" />行:
              </a-col>
              <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
                中国建设银行股份有限公司漳州东城支行
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="5" style="textAlign:right;">
                账<i style="paddingLeft:26px;" />号:
              </a-col>
              <a-col :span="19" style="textAlign:left;paddingLeft:20px;">
                35050166810700001235
              </a-col>
            </a-row>
          </div>
          <p>
            <img src="https://vip.51baocan.com/static/home/images/kefu.png" alt srcset />
          </p>
          <p>请及时添加客服微信或者联系客服电话:</p>
          <p>4009-180-190</p>
        </div>
      </a-modal>

      <a-modal v-model="showKefu" title="请扫描客服二维码" :footer="null" :width="300" :afterClose="goOrderList">
        <div style="width: 100%;text-align: center;">
          <img style="width:250px;margin: 0 auto;" src="https://styf.oss-cn-shenzhen.aliyuncs.com/uploads/images/qrcode/shouqian.png">
          <div>请微信扫码联系客服沟通详细需求</div>
        </div>
      </a-modal>

       <!-- 服务协议 -->
      <a-modal v-model="showRule" title="“食堂有饭”服务条款" :width="1000" @ok="showRule = false">
        <div v-html="serviceContent" style="display:block;"></div>
      </a-modal>
       <!-- 隐私协议 -->
      <a-modal v-model="showPrivacy" title="“食堂有饭”隐私政策" :width="1000" @ok="showPrivacy = false">
        <div v-html="privacyContent"></div>
      </a-modal>
  
    </div>
  </template>
  
  <script>
  import QRCode from "qrcodejs2";
  import { getServiceModule,payServiceOrder,payOrder,getPolicy } from "@/request/api";
  
  const payTypes = [
    {
      key: 1,
      value: '微信'
    },
    {
      key: 2,
      value: '支付宝'
    },
    {
      key: 3,
      value: '企业网银'
    },
    {
      key: 4,
      value: '对公汇款'
    },
    {
      key: 6,
      value: '快捷支付'
    }
  ];
  export default {
    data() {
      return {
        formLayout: 'horizontal',
        modules:[],
        payTypes,
        payType:'',
        order_number: '',//订单号
        showUnderLine:false,
        selectPayType:false,
        showWxScan:false,
        checkModule:0,
        num:1,
        price:0,
        total:'-',
        protocalChecked:false,
        mobile:'',
        showKefu:false,
        description:'',
        showRule:false,
        showPrivacy:false,
        serviceContent:'',
        privacyContent:''
      };
    },
    computed: {
     
    },
    components: {},
    created() {
      this.getServiceModule();//获取配置包
      this.getPolicyFn();//获取用户协议
    },
    watch: {
      
    },
    mounted() {},
    methods: {
        checkSel(idx,price,description){
            this.checkModule = idx;
            this.price = price;
            this.total = price == 0?'议价':'￥'+ (price * this.num);
            this.description = description;
        },
        onChange(e){
            console.log(e);
            if(this.checkModule == 0){
                return;
            }

            this.total = this.price == 0?'议价':'￥'+ (this.price * this.num);
        },
        checkContract(e) {
            let r = e.target.checked
            this.protocalChecked = r;
            console.log('changed', r);
        },
        goServiceOrder(){
          this.$router.push({
            path: "/ServiceOrder",
          });
          this.$store.commit('UPDATE_SELECTEDKEYS',['/ServiceOrder'])
        },
        async getPolicyFn(){
          let that = this;
          let parames = {}
          getPolicy(parames).then((res) => {
            let info = res.data.data;
            that.serviceContent = info.service.content;
            that.privacyContent = info.privacy.content;
          })
          .catch((err) => {
            
          });
        },
        async getServiceModule(){
            let that_ = this;
            //获取可购买的服务
            getServiceModule().then((res) => {
                console.log(res);
                that_.modules = res.data.data;
            });
        },
      
      /**
       * 确认支付
       */
        async toPay(){
          if(this.checkModule == 0){
            this.$message.error('请选择服务类型');
            return
            }
            
            if(this.mobile.length == 0){
                this.$message.error('请输入联系方式');
                return
            }

            if(this.num < 0){
                this.$message.error('请选择正确的次数');
                return
            }
            
            if(!this.protocalChecked){
              this.$message.error('请阅读并勾选服务协议后进行支付');
              return
            }
  
            let that = this;
            let parames = {
                mid:that.checkModule,
                num:that.num,
                mobile:that.mobile
            };
            payServiceOrder(parames).then((res) => {
                
                if(res.data.pay == 1){
                    that.order_number = res.data.order_number
                    //打开支付方式选择
                    that.selectPayType = true;
                } else {
                    that.showKefu = true;
                    // this.$router.push({
                    //     path: "tips",
                    // });
                }
            })
            .catch((err) => {
                that.$message.error(err.data.message);
            });
        },
      async changePayType (e) {
        let mode  = e.target.value
        let that_ = this;
        try {
              let { data } = await payOrder({
                  mode,
                  order_number: that_.order_number
              })
  
              if (mode == 1) {
                // 微信
                that_.showWxScan = true;
                that_.$refs.qrCodeUrl.innerHTML = ""; //先清除二维码
                var qrcode = new QRCode(that_.$refs.qrCodeUrl, {
                  text: data.url,
                  width: 150,
                  height: 150,
                  colorDark: "#000000",
                  colorLight: "#ffffff",
                  correctLevel: QRCode.CorrectLevel.H,
                });
              }
              if (mode == 2) {
                //支付宝
                document.getElementById("aliPay").innerHTML = data;
                const div = document.createElement("div"); // 创建div
                div.innerHTML = data; // 将返回的form 放入div
                document.getElementById("aliPay").appendChild(div);
                //document.forms[0].submit();
                let aliForm = document.querySelector("#aliPay form");
                aliForm.submit();
              }
              if (mode == 3) {
                //企业网银
                document.getElementById("unionPay").innerHTML = data;
                const div = document.createElement("div"); // 创建div
                div.innerHTML = data; // 将返回的form 放入div
                document.getElementById("unionPay").appendChild(div);
                // document.forms[0].submit();
                let unionForm = document.querySelector("#unionPay form");
                unionForm.submit();
              }
              if (mode == 4) {
                //线下
                that_.showUnderLine = true
                that_.selectPayType = false
              }
              if (mode == 6) {
                //快捷支付
                document.getElementById("quickPay").innerHTML = data;
                const div = document.createElement("div"); // 创建div
                div.innerHTML = data; // 将返回的form 放入div
                document.getElementById("quickPay").appendChild(div);
                // document.forms[0].submit();
                let quickForm = document.querySelector("#quickPay form");
                quickForm.submit();
              }
            } catch (err) {
              // let { errors } = err.data;
              // for (let i in errors) {
              //   this.$message.error(errors[i][0]);
              // }
            }
  
      },
      /*
       *@functionName: 选择支付方式-取消
       *@description:
       *@author: zhangchenhui
       *@date: 2021-02-06 16:36:03
      */
      noSurePayType () {
        this.$message.info('当前订单已创建,您可以到订单与发票管理中进行管理')
        this.payType = ''
        if (this.$refs.qrCodeUrl) {
          this.$refs.qrCodeUrl.innerHTML = ""; //清除微信付款二维码
        }
        this.showWxScan = false;
        this.selectPayType = false
      },
  
      /*
       *@functionName: 微信支付成功
       *@description:
       *@author: zhangchenhui
       *@date: 2021-02-07 10:12:57
      */
      async wxPaySuccess () {
        if(this.defaultActiveKey == '4'){
          await this.updateAuths()
        }
        this.payType = ''
        if (this.$refs.qrCodeUrl) {
          this.$refs.qrCodeUrl.innerHTML = ""; //清除微信付款二维码
        }
        this.showWxScan = false;
        this.selectPayType = false
        this.$router.push({
          path: "/ServiceOrder",
        });
        this.$store.commit('UPDATE_SELECTEDKEYS',['/ServiceOrder'])
      },
      goOrderList(){
        this.$router.push({
          path: "/OrderDetails",
        });
        this.$store.commit('UPDATE_SELECTEDKEYS',['/ServiceOrder'])
      },
      difference(arr1, arr2){
         return arr1.reduce(function(previous, i) {
               var found = arr2.findIndex(function(j) {
                   return j === i || (isNaN(i) && isNaN(j));
               });
               return (found < 0 && previous.push(i), previous);
           }, []);
      },
      getModuleChangeMsg(){
        let th = this
        // let newModules = th.selectedRowKeys.filter(it=>{
        //   return th.instModules.indexOf(it)===-1
        // })
        let removeModules = th.instModules.filter(it=>{
          return th.selectedRowKeys.indexOf(it)===-1
        })
        //let newModuleTitles = []
        let removeModuleTitles = []
        this.modules.forEach(it=>{
          // if(newModules.indexOf(it.key)!==-1){
          //   newModuleTitles.push(it.title)
          // }
          if(removeModules.indexOf(it.key)!==-1){
            removeModuleTitles.push(it.title)
          }
        })
        // let str = []
        // if(newModuleTitles.length>0){
        //   str.push("新增模块："+newModuleTitles.join("，"));
        // }
        let str = ''
        if(removeModuleTitles.length>0){
          str = "即将关闭功能："+removeModuleTitles.join("，");
        }
        return str;
      },
      
    },
  };
  </script>
  <style lang='less' scoped>
  @import '~@/style/style.less';
  .summary{
      padding: 30px 25px;
      background-color: #fff;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
          margin-right:15px;
      }
  }
  .packet-items{
    padding: 20px 20px;
    border: 1px solid #EEE;
    margin-left: 12px;
    border-radius: 4px;
    line-height: 1px;
    cursor:pointer;
  }
  .active{
    background-color:#FF833D;
    color:#fff;
  }
  .time-active{
    border-color:rgb(82, 193, 245);
    color:rgb(82, 193, 245);
  }
  .version-list-title{
    display: flex;
    padding: 20px;
    margin: 30px 10px;

    .tips-info{
        color: #A29D9D;
        margin-top: 8px;
    }
  }
  .version-list{
    display: flex;
    margin-top: 5px;
    margin-right:5px;
    background-color:#fff;
    padding: 20px;
  }
  .renew-form{
    margin-top: 5px;
    background-color:#fff;
    padding: 20px 13px;
  }
  .num-level{
    margin-top: 15px;
    background-color:#fff;
    padding: 20px;
    margin-right: 5px;
    display: flex;
    align-items: top;
    .slider-label{width:80px;font-weight: bold;}
    .num-unlimit{width:200px;}
    .slider-box{width:calc(100% - 280px);}
  }
  .label{font-weight:bold;}
  .selected-modules{
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    min-height: 303px;
    margin-top: 5px;
    li{
      display: flex;
      justify-content: space-between;
      height:30px;line-height: 30px;
    }
  }
  .ant-form-item{
    margin-bottom: 0px;
  }
  .money-box{
    .money{font-size: 20px;}
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-content: center;
  }
  .protocol{
    font-size: 14px;
    a{color:#FF833D;}
  }
  ul.duration{
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
  /*  display: flex;*/
    li:first-child{
      width:100%;
      span{
        display: inline-block;
        height:26px;line-height:26px;
        padding: 0px 12px;
        border:1px solid #EEE;
      }
      span:hover{
        background-color:#FF833D;
        color:#fff;
        cursor: pointer;
      }
      &.active{
        background:#fff;
        span{
          background-color:#FF833D;
          color:#fff;
          cursor: pointer;
        }
      }
    }
    li:not(:first-child){
      display: inline-block;
      padding: 0px 12px;
      margin-right: 5px;
      border:1px solid #EEE;
      height:26px;line-height:26px;
      margin-top: 7px;
      position:relative;
      cursor:pointer;
      &:hover,&.active{
        background-color:#FF833D;
        color:#fff;
      }
      .duration-tip{
        position: absolute;
        background-color:#FF833D;
        right:0px;
        top: -16px;
        font-size:12px;
        color:#666;
        height:16px;
        line-height:16px;
        padding: 0px 2px;
  
      }
    }
  }
  ul.num-checkbox{
    list-style: none;
    margin-left: 0px;
    padding-left: 0px;
    display: flex;
    li{
      display: inline-block;
      padding: 0px 10px;
      margin-right: 5px;
      border:1px solid #EEE;
      height:32px;line-height:32px;
      margin-top: 0px;
      position:relative;
      cursor:pointer;
      &:hover,&.active{
        background-color:#FF833D;
        color:#fff;
      }
    }
  }
  .tips{
      padding: 0px 15px 10px;
      font-size: 14px;
  }
  .tips a{
    color: #FF833D;
    margin: 0 6px;
  }
  .ant-modal-body span{ text-wrap: wrap!important; }
  .custom-cell{
    position: relative;
  }
  .check-tip{
    display: inline-block;
    position:absolute;
    left: 0px;
    top:0px;
    width:28px;
    height:21px;
    background:none;
    margin-left:-59px;
    z-index:1000;
  }
  .banner-btn{
    margin-right: 15px;
    color: #fff;
    background:linear-gradient(to top, #FF833D, #ffb285);
  }

  .foot{
    // position: absolute;
    // bottom: 0;
    background-color: #fff;
    // width: 100%;
    // display: flex;
    // justify-content: flex-end;
    border-top: 1px solid #eee;

    .foot-item{
        margin-right: 20px;
        padding-top: 25px;

        .foot-item-list{
            display: flex;
            justify-content: flex-end;
        }
    }
  }
  </style>
  